<template>
  <v-container fluid class="about">
    <div>아크키친이란?</div>
  </v-container>
</template>
<script>
export default {
  components: {

  },
  name: 'About',
  mounted() {
    
  },
  methods: {
    
  },
  data() {
    return {
      
    }
  },
  computed: {

  }
}
</script>
<style lang="scss" scoped>
.about {
  padding-top: 128px;  
} 
</style>